/* App.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


.container {
    max-width: 50rem; /* 800px / 16px = 50rem */
    margin: 0 auto;
    padding: 1.25rem 1.25rem 0.5rem 1.25rem; /* 20px / 16px = 1.25rem */
    display: grid;
    grid-template-rows: auto 1fr auto;
    height: 100vh;
    max-height: 100%;
    box-sizing: border-box;
    overflow: hidden
}

body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-size: 16px; /* Base font size for rem calculations */
}

#root {
    height: 100%;
}

.header {
    display: grid;
    justify-items: center;
    margin-bottom: 1.25rem;
}

.title-container {
    display: grid;
    justify-items: center;
}

.main-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 900;
    font-size: 3.5rem;
    color: #263A95;
    letter-spacing: 0.125rem;
    text-transform: uppercase;
    margin-bottom: 0rem;
    text-shadow: 0.2rem 0.2rem 0.25rem rgba(0,0,0,0.1);
}

.subtitle {
    font-family: 'Poppins', ;
    font-weight: 400;
    font-size: 1.5rem;
    color: #90a0e7;
    letter-spacing: 0.03125rem;
    margin-top: 0rem;
    font-style: italic;
    text-shadow: 0.0625rem 0.0625rem 0.125rem rgba(0,0,0,0.05);
}

.chatbot-wrapper {
    overflow: hidden;
    min-height: 18.75rem; /* 300px / 16px = 18.75rem */
}

.footer {
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10%;
    align-items: center;
    margin-top: 0.75rem;
    padding-top: 0.5rem;
    border-top: 1px solid #e0e0e0;
}

.logotext-container {
    display: grid;
    grid-template-columns: auto auto;
    gap: 0.625rem;
    align-items: center;
}

.logotext-container:first-child {
    justify-content: start;
    padding-left: 2rem;
}

.logotext-container:last-child {
    justify-content: end;
    padding-right: 2rem;
}

.logo-container {
    display: grid;
    grid-template-columns: auto auto;
    justify-items: center;
    gap: 0.625rem;
    align-items: center;
}

.logo-text {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 0.9rem;
    color: #757575;
    text-align: right;
    justify-self: end;
}

.logo {
    height: 2.5rem; /* 40px / 16px = 2.5rem */
    width: auto;
    justify-self: start;
}

.logo-left {
    /* Adjust as needed for AACE logo */
    height: 2.25rem; /* 50px */
    width: auto;
}

.logo-right {
    /* Adjust as needed for probid logo */
    height: 2.25rem; /* 40px */
    width: auto;
}

.avatar {
    justify-self: center;
    width: 7.5rem; /* 200px */
    height: 7.5rem; /* 200px */
    border-radius: 50%;
    box-shadow: 0 0.25rem 0.375rem rgba(129, 22, 24, 0.3);
}

/* Responsive adjustments */
@media (max-width: 37.5rem) {
    .main-title {
        font-size: 2.5rem;
    }

    .subtitle {
        font-size: 1rem;
    }

    .footer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: stretch;
        margin-top: 0.5rem;
    }

    .logotext-container {
        display: grid;
        grid-template-columns: 1fr; /* Ensure single column layout */
        grid-template-rows: auto 1fr;
        justify-items: center;
        gap: 0.2rem;
        min-height: 0;
    }

    .logo {
        align-self: center;
        justify-self: center;
        max-width: 100%;
        object-fit: contain;
    }

    .logo-text {
        text-align: left;
        justify-self: center;
        order: -1;
        font-size: 0.8rem;
    }

    .logotext-container:first-child {
        justify-items: start;
        padding-left: 0.5rem;
    }
    .logotext-container:last-child {
        justify-items: end;
        padding-right: 0.5rem;
    }

    .logo-left {
        /* Adjust as needed for AACE logo */
        height: 2em; /* 50px */
        width: auto;
    }
    
    .logo-right {
        /* Adjust as needed for probid logo */
        height: 2rem; /* 40px */
        width: auto;
    }
}