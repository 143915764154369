/* Chatbot.css */

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

#chatbox {
    display: grid;
    grid-template-rows: 1fr auto;
    background-color: #f8f8f8;
    border-radius: 0.5rem;
    box-shadow: 0 0.125rem 0.625rem rgba(129, 22, 24, 0.2);
    height: 100%; /* 70% of the viewport height */
    min-height: 25rem; /* Minimum height */
    padding: 2rem 2rem 1.5rem 2rem;
    margin-bottom: 1.25rem;
    overflow: hidden;
}

.messages-container {
    max-width: 100%;
    overflow-y: auto;
    display: grid;
    align-content: start;
    gap: 0.9375rem;
    padding-right: 0.75rem;
}

#userInput {
    display: grid;
    margin-top: 1.25rem;
}

#textInput {
    width: 100%;
    border: none;
    border-radius: 1.25rem;
    padding: 0.625rem 1.25rem;
    font-family: poppins, sans-serif;
    font-size: 1rem;
    box-shadow: 0 0.125rem 0.3125rem rgba(129, 22, 24, 0.1);
    color: #797979;
}

#textInput:focus {
    outline: none;
    box-shadow: 0 0.125rem 0.3125rem rgba(129, 22, 24, 0.3);
}

.message-wrapper {
    display: grid;
    grid-template-columns: 1fr; /* Creates a single column */
    width: 100%;
}

.userText, .botText, .sourceText {
    max-width: 90%;
    line-height: 1.4;
}

.userText, .botText {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.botText {
    justify-self: start;
    text-align: left;
    padding-left: 0.3125rem;
}

.botText h3 {
    margin-bottom: 0.25em;
    margin-top: 0.5em;
}



.botText > div {
    background-color: #f1e5f4;
    color: black;
    padding: 0.625rem 0.9375rem;
    border-radius: 1.125rem 1.125rem 1.125rem 0;
    display: inline-block;
    max-width: 100%;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.botText p {
    margin: 0;
}

.botText ol {
    counter-reset: list-counter; /* Resets the list counter for ordered lists */
    list-style: none; /* Remove default numbering */
    padding-left: 1.5em; /* Indent the list */
}

.botText ol > li {
    counter-increment: list-counter; /* Increments the counter */
    position: relative;
    padding-left: 1.5em; /* Space for the custom number */
    margin-bottom: 0.5em; /* Space between items */
}

.botText ol > li::before {
    content: counter(list-counter) ") "; /* Display the counter with a closing parenthesis */
    position: absolute;
    left: 0;
    font-weight: bold;
}

.botText ul {
    list-style-type: disc; /* Bullets for unordered lists */
    padding-left: 2em; /* Indent nested list items */
    margin-top: 0.5em; /* Space between the main item and sub-list */
}

.botText ul > li {
    margin-bottom: 0.2em; /* Space between sub-list items */
}

.botText li {
    margin: 0.2em 0;
}

.botText li:last-child {
    margin-bottom: 0.5em; /* Add extra space after the last list item */
}

.botText code {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 0.2em 0.4em;
    border-radius: 0.3em;
}

.botText table {
    /* border-collapse: collapse; */
    margin: 1rem 0;
    font-size: 0.8em;
    font-family: sans-serif;
    min-width: 100%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.botText thead tr {
    background-color: #e0e0e0;
    color: #000000;
    text-align: left;
}

.botText th,
.botText td {
    padding: 0.5rem 0.75rem;
}

.botText tbody tr {
    border-bottom: 1px solid #dddddd;
}

.botText tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.botText tbody tr:nth-of-type(odd) {
    background-color: #f3f3f3;
}

.botText tbody tr:last-of-type {
    border-bottom: 2px solid #a76264;
}

.botText tbody {
    color: #333333; /* Dark gray color for the table body text */
}

.botText th {
    color: #000000; /* White color for the table header text */
}

.botText td {
    color: #333333; /* Dark gray color for the table cell text */
}

.userText {
    justify-self: end;
    text-align: left;
    padding-right: 0.3125rem;
}

.userText pre, .botText pre {
    white-space: -moz-pre-wrap;  /* Firefox */
    white-space: -pre-wrap;      /* Opera <7 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    white-space: pre-wrap;       /* CSS3 */    
    word-wrap: break-word;       /* IE */
    word-break: break-word;      /* Modern browsers */
    max-width: 100%;
    overflow-x: hidden;
    padding: 0.625rem 0.9375rem;
    display: inline-block;
    margin: 0;
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
    font-weight: inherit;
}

.userText pre {
    background-color: #602370;
    color: white;
    padding: 0.625rem 0.9375rem;
    border-radius: 1.125rem 1.125rem 0 1.125rem;
    display: inline-block;
}

.botText pre {
    background-color: #263A95;
    color: white;
    padding: 0.625rem 0.9375rem;
    border-radius: 1.125rem 1.125rem 1.125rem 0;
    display: inline-block;
}

.message pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    font-family: inherit;
    margin: 0;
    font-size: inherit;
}

.sourceText {
    color: #797979;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: italic;
    margin: 0;
    font-size: 0.9em;
}

.message-wrapper .sourceText:first-child {
    margin-top: 1.5em; /* Add space before the first source text */
}

.botText + .sourceText {
    margin-top: 0.5em;
}

/* Scrollbar styling */
.messages-container::-webkit-scrollbar {
    width: 0.5rem;
}

.messages-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 0.25rem;
}

.messages-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 0.25rem;
}

.messages-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}


.loading-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  
  .dot {
    background-color: #263A95;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    animation: bounce 1.4s infinite ease-in-out both;
  }
  
  .dot:nth-child(1) {
    animation-delay: -0.32s;
  }
  
  .dot:nth-child(2) {
    animation-delay: -0.16s;
  }
  
  @keyframes bounce {
    0%, 80%, 100% { 
      transform: scale(0);
    } 40% { 
      transform: scale(1.0);
    }
  }
  