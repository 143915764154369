/* index.css */

/* Reset some default browser styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body, html {
    height: 100%;
    font-family: "Poppins", sans-serif;
    background-color: #f0f0f0;
    color: #797979;
}

/* Scrollbar styles */
::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    background: #f0f0f0;
}
::-webkit-scrollbar-thumb {
    background: #a76264;
    border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
    background: #811618;
}
